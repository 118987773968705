/* == react modules */
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

/* == components */
import ArtistsStatement from "./containers/ArtistsStatement";
import Home from "./containers/Home";
import Resume from "./containers/Resume";
import WordPlay from "./containers/WordPlay";
import Works from "./containers/Works";

/* == components */
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

/* == styles */
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <Router>
      <>
        <div className="app-container">
          <Navigation />
          <Route exact path="/" component={Home} />
          <Route path="/artists-statement" component={ArtistsStatement} />
          <Route path="/resume" component={Resume} />
          <Route path="/word-play" component={WordPlay} />
          <Route path="/works/:section" component={Works} />
          <Footer />
        </div>
      </>
    </Router>
  );
}

export default App;
