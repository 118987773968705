/* == react modules */
import React from "react";

/* == node libraries */
import { Container, Col, Row } from "react-bootstrap";

function ArtistsStatement() {
  return (
    <Container className="mt-4" fluid="sm">
      <Row>
        <Col>
          <h1 className="border-bottom border-secondary pb-2 mb-4">
            Artist's Statement
          </h1>
          <p>
            Growing up in a small town in southeastern Missouri, I had a lot of
            down time. With the exception of school and chores, there were few
            planned activities, leaving great spaces of time to dream, think and
            draw. Drawing became a escape from boredom to a world of
            possibilities. I eventually attended art school at Washington
            University School of Fine Arts in St. Louis, and worked in the
            fashion industry for a short five year career before retiring to
            marriage and raising a family. Though I managed to be creative while
            raising a family, it wasn't until later in life that I finally had
            the time and a studio space to devote to my creative endeavors. My
            studio is the place where I can become myself and express myself
            through my work.
          </p>
          <p>
            Except for foundation courses in Art School, a year in the
            Illustration Dept. and Continued Ed. Classes at RISD, my painting
            has been a personal journey.
          </p>
          <p>
            For me, the experience of painting is always the tensions between
            concept and process. I start each painting with an idea that I work
            out in sketches to understand the subject through value and
            composition. I transfer these thoughts to canvas, putting away the
            sketch and any photo reference I may have started with, and allow
            that the process to take over. It is the time when the painting
            demands a certain color, space or line. This happens at different
            times depending on the painting and during the process my thinking
            evolves and germinates into what will become the next painting. I
            often work in series, ie. Window and Tree Series.
          </p>
          <p>
            In working with Still Life's, the process is somewhat different in
            that I always work with set-ups where most of the painting will be
            about the careful observation of objects and translating them to a
            two dimensional surface Nevertheless, I begin with the same
            sketching and thought process before painting on the canvas. I want
            to say something about the still life and present it in a way to
            invite the viewer into the painting.
          </p>
          <p>
            In the end, I believe painting is about the balance between concept
            and process, idea and intuition, thought and feeling.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default ArtistsStatement;
