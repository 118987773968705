/* == react modules */
import React from "react";

/* == node libraries */
import { Navbar } from "react-bootstrap";

function Footer() {
  return (
    <Navbar
      className="d-flex justify-content-center"
      fixed="bottom"
      bg="light"
      variant="light"
    >
      <div className="">Fine works in oil &amp; pastel</div>
    </Navbar>
  );
}

export default Footer;
