/* == react modules */
import React from "react";

/* == node libraries */
import { Container, Col, Row } from "react-bootstrap";

function WordPlay() {
  return (
    <Container className="mt-4" fluid="sm">
      <Row>
        <Col>
          <h1 className="border-bottom border-secondary pb-2 mb-4">
            Word Play
          </h1>
          <p>
            Words matter, but when creating art I have found assigning titles
            for my paintings a nuisance. All too often titles are an
            afterthought and appear irrelevant to the process of creating the
            painting.
          </p>
          <p>
            I decided to have some fun using familiar phases and idioms for
            titles and creating the painting to go with the words. The title of
            this exhibition is “Word Play” and it consists of a collection of
            twenty small Still Life paintings. I have attempted to paint each
            work as a serious and credible work of art, but leaven the paintings
            with a little whimsy.
          </p>
          <p>
            The starting point of each painting in this exhibition is the
            concept. To achieve the visual effects of my idea, I work very
            deliberately on the preliminary sketches, defining as clearly as
            possible the relationship between the value and tone which in turn
            creates the structure of the composition. Using a limited palette, I
            paint from still life set ups in my studio, choosing objects from my
            collection in my studio, or things barrowed from friends and home.
          </p>
          <p>
            If I am successful in my endeavor the viewer will leave this
            exhibition with a smile{" "}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default WordPlay;
