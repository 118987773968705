const paintings = {
  home: [
    {
      imageFullsize: "MtPhiloRdBarnII.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Mt. Philo Rd. Barn I [sold] | 2007 | Pastel/Watercolor | 17.0&quot; x 17.0&quot;",
      titleShort: "Mt. Philo Rd. Barn I",
      imageTn: "MtPhiloRdBarnII.jpg",
    },
    {
      imageFullsize: "11-Window-Series-In-Blue-Oil-20x20.jpg",
      title: "Window Series In Blue &mdash; Oil, 20x20",
      titleShort: "Window Series In Blue",
    },
    {
      imageFullsize: "12-Window-Series-in-Red-Oil-20x20.jpg",
      title: "Window Series in Red &mdash; Oil, 20x20",
      titleShort: "Window Series in Red",
    },
    {
      imageFullsize: "04-Brown-Paper-Series-II-Oil-14x18.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Brown Paper Series II &mdash; Oil, 14x18",
      titleShort: "Brown Paper Series II &mdash; Oil, 14x18",
      imageTn: "/assets/paintings/tn/04-Brown-Paper-Series-II-Oil-14x18.jpg",
    },
    {
      imageFullsize: "02-Inheritance-II-Oil-22x28.jpg",
      title: "Inheritance-II-Oil-22x28",
      titleShort: "Inheritance-II",
    },
  ],
  landscapes: [
    {
      imageFullsize: "EveningShadows.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Evening Shadows | 2006 | Pastel/Watercolor | 241.0&quot; x 241.0&quot;",
      titleShort: "Eveningshadows",
      imageTn: "EveningShadows.jpg",
    },
    {
      imageFullsize: "VermontFields_I.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Vermont Fields I [sold] | 2007 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Vermontfields_i",
      imageTn: "VermontFields_I.jpg",
    },
    {
      imageFullsize: "IMG_2435.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Morning Shadows, Maui HI | 2008 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Morning Shadows",
      imageTn: "IMG_2435.jpg",
    },
    {
      imageFullsize: "IMG_2328.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Alpine Mist, South Island, NZ | 2008 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Alpine Mist, South Island",
      imageTn: "IMG_2328.jpg",
    },
    {
      imageFullsize: "IMG_2323.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Shining Through, South Is. NZ | 2008 | Pastel/Watercolor | 15.0&quot; x 15.0&quot;",
      titleShort: "Shining Through",
      imageTn: "IMG_2323.jpg",
    },
    {
      imageFullsize: "IMG_2324.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Alpine Lupin, South Island, NZ | 2008 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Alpine Lupin",
      imageTn: "IMG_2324.jpg",
    },
    {
      imageFullsize: "MtPhiloRdBarnII.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Mt. Philo Rd. Barn I [sold] | 2007 | Pastel/Watercolor | 17.0&quot; x 17.0&quot;",
      titleShort: "Mt. Philo Rd. Barn I",
      imageTn: "MtPhiloRdBarnII.jpg",
    },
    {
      imageFullsize: "IMG_1843.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Barn Door [sold] | 2007 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Barn Door",
      imageTn: "IMG_1843.jpg",
    },
    {
      imageFullsize: "IMG_1853.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Barn Shadows II | 2007 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Barn Shadows II",
      imageTn: "IMG_1853.jpg",
    },
    {
      imageFullsize: "IMG_2157.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Westport River, West Branch [sold] | 2007 | Pastel/Watercolor | 24.0&quot; x 24.0&quot;",
      titleShort: "Westport River, West Branch",
      imageTn: "IMG_2157.jpg",
    },
    {
      imageFullsize: "IMG_1839.jpg?1236918650",
      rel: "lightbox-paintings-Landscapes",
      title: "Red Barn at High Noon | 2007 | Pastel | 14.0&quot; x 14.0&quot;",
      titleShort: "Red Barn at High Noon",
      imageTn: "IMG_1839.jpg?1236918650",
    },
    {
      imageFullsize: "MidwayLightI.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Midway Light, Heber Valley, UT | 2006 | Pastel/Watercolor | 18.0&quot; x 18.0&quot;",
      titleShort: "Midway Light, Heber Valley",
      imageTn: "MidwayLightI.jpg",
    },
    {
      imageFullsize: "IMG_1828.jpg?1236918792",
      rel: "lightbox-paintings-Landscapes",
      title:
        "View from Kitchen Window | 2007 | Pastel | 18.0&quot; x 18.0&quot;",
      titleShort: "View from Kitchen Window",
      imageTn: "IMG_1828.jpg?1236918792",
    },
    {
      imageFullsize: "IMG_2437.jpg?1236919625",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Sugar Cane Fields, Maui, HI | 2008 | Pastel | 14.0&quot; x 14.0&quot;  Sold",
      titleShort: "Sugar Cane Fields",
      imageTn: "IMG_2437.jpg?1236919625",
    },
  ],

  stillLifes: [
    {
      imageFullsize: "IMG_2382_1.jpg?1236920478",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Orange on Silver | 2008 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Img_2382_1",
      imageTn: "IMG_2382_1.jpg?1236920478",
    },
    {
      imageFullsize: "IMG_2313.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Tangarines on Red | 2008 | Pastel/Watercolor | 10.0&quot; x 10.0&quot;",
      titleShort: "Img_2313",
      imageTn: "IMG_2313.jpg",
    },
    {
      imageFullsize: "IMG_2129.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Book Study I | 2007 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Img_2129",
      imageTn: "IMG_2129.jpg",
    },
    {
      imageFullsize: "IMG_2146.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Book Study II | 2007 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2146",
      imageTn: "IMG_2146.jpg",
    },
    {
      imageFullsize: "IMG_2165.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Book Study III | 2007 | Pastel/Watercolor | 18.0&quot; x 18.0&quot;",
      titleShort: "Img_2165",
      imageTn: "IMG_2165.jpg",
    },
    {
      imageFullsize: "IMG_2280.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Nature Collection I | 2008 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2280",
      imageTn: "IMG_2280.jpg",
    },
    {
      imageFullsize: "IMG_1777.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Blue Canton IV | 2007 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_1777",
      imageTn: "IMG_1777.jpg",
    },
    {
      imageFullsize: "IMG_2535.jpg?1236920713",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Pears with Mortar and Pestle | 2008 | Pastel | 16.0&quot; x 16.0&quot;",
      titleShort: "Pears with Mortar and Pestle",
      imageTn: "IMG_2535.jpg?1236920713",
    },
    {
      imageFullsize: "IMG_2338.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Hanging Grapes | 2008 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2338",
      imageTn: "IMG_2338.jpg",
    },
    {
      imageFullsize: "IMG_2585.jpg?1236921079",
      rel: "lightbox-paintings-Still Lifes",
      title: "Hanging Artichoke II | 2008 | Pastel | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2585",
      imageTn: "IMG_2585.jpg?1236921079",
    },
    {
      imageFullsize: "MangoDuet.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Mango Duet [sold] | 2007 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Mango Duet",
      imageTn: "MangoDuet.jpg",
    },
    {
      imageFullsize: "IMG_2604.jpg?1236921031",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Still Life with Grapes and Pears | 2008 | Pastel | 9.0&quot; x 9.0&quot;",
      titleShort: "Img_2604",
      imageTn: "IMG_2604.jpg?1236921031",
    },
    {
      imageFullsize: "IMG_1838_1.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Apples in a Basket [sold] | 2007 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Img_1838_1",
      imageTn: "IMG_1838_1.jpg",
    },
    {
      imageFullsize: "IMG_2569.jpg?1236920972",
      rel: "lightbox-paintings-Still Lifes",
      title: "Garlic Clove | 2008 | Pastel | 6.0&quot; x 6.0&quot;",
      titleShort: "Img_2569",
      imageTn: "IMG_2569.jpg?1236920972",
    },
    {
      imageFullsize: "IMG_2554.jpg?1236920921",
      rel: "lightbox-paintings-Still Lifes",
      title: "Red Grapes | 2008 | Pastel | 6.0&quot; x 6.0&quot;",
      titleShort: "Img_2554",
      imageTn: "IMG_2554.jpg?1236920921",
    },
  ],

  botanicals: [
    {
      imageFullsize: "Sturim_020.jpg",
      rel: "lightbox-paintings-Botanicals",
      title:
        "Morning Dew I | 2005 | Pastel/Watercolor | 12.0&quot; x 12.0&quot;",
      titleShort: "Sturim_020",
      imageTn: "Sturim_020.jpg",
    },
    {
      imageFullsize: "Sturim_021.jpg",
      rel: "lightbox-paintings-Botanicals",
      title:
        "Morning Dew II [sold] | 2005 | Pastel/Watercolor | 12.0&quot; x 12.0&quot;",
      titleShort: "Sturim_021",
      imageTn: "Sturim_021.jpg",
    },
    {
      imageFullsize: "Sturim_038.jpg",
      rel: "lightbox-paintings-Botanicals",
      title: "Lotus I | 2005 | Pastel/Watercolor | 17.0&quot; x 17.0&quot;",
      titleShort: "Sturim_038",
      imageTn: "Sturim_038.jpg",
    },
    {
      imageFullsize: "Sturim_039.jpg",
      rel: "lightbox-paintings-Botanicals",
      title: "Ladyslipper | 2005 | Pastel/Watercolor | 17.0&quot; x 17.0&quot;",
      titleShort: "Sturim_039",
      imageTn: "Sturim_039.jpg",
    },
  ],
  oils: [
    {
      imageFullsize: "01-Inheritance-I-Oil-10x20.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Inheritance I &mdash; Oil, 10x20",
      titleShort: "Inheritance-I-Oil-10x20",
      imageTn: "/assets/paintings/tn/01-Inheritance-I-Oil-10x20.jpg",
    },
    {
      imageFullsize: "02-Inheritance-II-Oil-22x28.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Inheritance II &mdash; Oil, 22x28",
      titleShort: "Inheritance-II-Oil-22x28",
      imageTn: "/assets/paintings/tn/02-Inheritance-II-Oil-22x28.jpg",
    },
    {
      imageFullsize: "03-Goldfinch-&-Company-Oil-18x24.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Goldfinch &amp; Company &mdash; Oil, 18x24",
      titleShort: "Goldfinch &amp; Company &mdash; Oil, 18x24",
      imageTn: "/assets/paintings/tn/03-Goldfinch-&-Company-Oil-18x24.jpg",
    },
    {
      imageFullsize: "04-Brown-Paper-Series-II-Oil-14x18.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Brown Paper Series II &mdash; Oil, 14x18",
      titleShort: "Brown Paper Series II &mdash; Oil, 14x18",
      imageTn: "/assets/paintings/tn/04-Brown-Paper-Series-II-Oil-14x18.jpg",
    },
    {
      imageFullsize: "05-Robin-and-the-Moth-Oil-18x24.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Robin and the Moth &mdash; Oil, 18x24",
      titleShort: "Robin and the Moth &mdash; Oil, 18x24",
      imageTn: "/assets/paintings/tn/05-Robin-and-the-Moth-Oil-18x24.jpg",
    },
    {
      imageFullsize: "06-Alpinglow-Cedar-Oil-24x24.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Alpinglow Cedar &mdash; Oil, 24x24",
      titleShort: "Alpinglow Cedar &mdash; Oil, 24x24",
      imageTn: "/assets/paintings/tn/06-Alpinglow-Cedar-Oil-24x24.jpg",
    },
    {
      imageFullsize: "07-Aspens-in-Greens-&-Reds-Oil-20x24.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Aspens in Greens &amp; Reds &mdash; Oil, 20x24",
      titleShort: "Aspens in Greens &amp; Reds &mdash; Oil, 20x24",
      imageTn: "/assets/paintings/tn/07-Aspens-in-Greens-&-Reds-Oil-20x24.jpg",
    },
    {
      imageFullsize: "08-Autumn-Aspen-Grove-Oil-18x24.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Autumn Aspen Grove &mdash; Oil, 18x24",
      titleShort: "Autumn Aspen Grove &mdash; Oil, 18x24",
      imageTn: "/assets/paintings/tn/08-Autumn-Aspen-Grove-Oil-18x24.jpg",
    },
    {
      imageFullsize: "09-Daybreak-Oil-18x24.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Daybreak &mdash; Oil, 18x24",
      titleShort: "Daybreak &mdash; Oil, 18x24",
      imageTn: "/assets/paintings/tn/09-Daybreak-Oil-18x24.jpg",
    },
    {
      imageFullsize: "10-Looking-Through-Oil-24x20.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Looking Through &mdash; Oil, 24x20",
      titleShort: "Looking Through &mdash; Oil, 24x20",
      imageTn: "/assets/paintings/tn/10-Looking-Through-Oil-24x20.jpg",
    },
    {
      imageFullsize: "11-Window-Series-In-Blue-Oil-20x20.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Window Series In Blue &mdash; Oil, 20x20",
      titleShort: "Window Series In Blue &mdash; Oil, 20x20",
      imageTn: "/assets/paintings/tn/11-Window-Series-In-Blue-Oil-20x20.jpg",
    },
    {
      imageFullsize: "12-Window-Series-in-Red-Oil-20x20.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Window Series in Red &mdash; Oil, 20x20",
      titleShort: "Window Series in Red &mdash; Oil, 20x20",
      imageTn: "/assets/paintings/tn/12-Window-Series-in-Red-Oil-20x20.jpg",
    },
    {
      imageFullsize: "13-Habitat-Oil-24x36.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Habitat &mdash; Oil, 24x36",
      titleShort: "Habitat &mdash; Oil, 24x36",
      imageTn: "/assets/paintings/tn/13-Habitat-Oil-24x36.jpg",
    },
    {
      imageFullsize: "14-Fall-Colors-&-Red-Barn-Oil--12x-24.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Fall Colors &amp; Red Barn &mdash; Oil 12x24",
      titleShort: "Fall Colors &amp; Red Barn &mdash; Oil 12x24",
      imageTn: "/assets/paintings/tn/14-Fall-Colors-&-Red-Barn-Oil--12x-24.jpg",
    },
    {
      imageFullsize: "15-Autumn-Fields-Oil-8x10.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Autumn Fields &mdash; Oil, 8x10",
      titleShort: "Autumn Fields &mdash; Oil, 8x10",
      imageTn: "/assets/paintings/tn/15-Autumn-Fields-Oil-8x10.jpg",
    },
    {
      imageFullsize: "16-Winterscape-Oil-6x12.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Winterscape &mdash; Oil, 6x12",
      titleShort: "Winterscape &mdash; Oil, 6x12",
      imageTn: "/assets/paintings/tn/16-Winterscape-Oil-6x12.jpg",
    },
    {
      imageFullsize: "20-Autumn-Triad-Oil-24x24-jpg-72dpi.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Autumn Triad &mdash; Oil, 24x24",
      titleShort: "Autumn Triad &mdash; Oil, 24x24",
      imageTn: "/assets/paintings/tn/20-Autumn-Triad-Oil-24x24-jpg-72dpi.jpg",
    },
    {
      imageFullsize: "May-2011-As-Time-Goes-By-Oil-22x25.jpg",
      rel: "lightbox-paintings-oils",
      title: "As Time Goes By | 2011 | Oil | 22&quot; x 25&quot;",
      titleShort: "As Time Goes By Oil",
      imageTn: "May-2011-As-Time-Goes-By-Oil-22x25.jpg",
    },

    {
      imageFullsize: "May-2011-Common-Threads-Oil-14x18.jpg",
      rel: "lightbox-paintings-oils",
      title: "Common Threads | 2011 | Oil | 14.0&quot; x 18.0&quot;",
      titleShort: "Common Threads",
      imageTn: "May-2011-Common-Threads-Oil-14x18.jpg",
    },
    {
      imageFullsize: "May-2011-High-Five-Oil-16x19.jpg",
      rel: "lightbox-paintings-oils",
      title: "High Five | 2011 | Oils | 16.0&quot; x 19.0&quot;",
      titleShort: "High Five",
      imageTn: "May-2011-High-Five-Oil-16x19.jpg",
    },
    {
      imageFullsize: "May-2011-Hole-In-One-Oil-12-x-12.jpg",
      rel: "lightbox-paintings-oils",
      title: "Hole In One Oil | 2011 | Oils | 12.0&quot; x 12.0&quot;",
      titleShort: "Hole In One",
      imageTn: "May-2011-Hole-In-One-Oil-12-x-12.jpg",
    },
    {
      imageFullsize: "May-2011-Menage-a-tois-oil-17x21.jpg",
      rel: "lightbox-paintings-oils",
      title: "Ménage à trois | 2011 | 17.0&quot; x 21.0&quot;",
      titleShort: "Ménage à trois",
      imageTn: "May-2011-Menage-a-tois-oil-17x21.jpg",
    },
    {
      imageFullsize: "May-2011-Mount-of-Olives-Oil-14x14.jpg",
      rel: "lightbox-paintings-oils",
      title: "Mount of Olives | 2011 | Oils | 14.0&quot; x 14.0&quot;",
      titleShort: "Mount of Olives",
      imageTn: "May-2011-Mount-of-Olives-Oil-14x14.jpg",
    },
    {
      imageFullsize: "May-2011-Nest-Egg-Oil-16x19.jpg",
      rel: "lightbox-paintings-oils",
      title: "Nest Egg | 2011 | Oils | 16.0&quot; x 19.0&quot;",
      titleShort: "Nest Egg",
      imageTn: "May-2011-Nest-Egg-Oil-16x19.jpg",
    },
    {
      imageFullsize: "May-2011-No-Strings-Attached-Oil-22x25.jpg",
      rel: "lightbox-paintings-oils",
      title: "No Strings Attached | 2011 | Oils | 22.0&quot; x 25.0&quot;",
      titleShort: "No Strings Attached",
      imageTn: "May-2011-No-Strings-Attached-Oil-22x25.jpg",
    },
    {
      imageFullsize: "May-2011-Sitting-on-Pins-And-Needles-Oil-12-x-12.jpg",
      rel: "lightbox-paintings-oils",
      title:
        "Sitting on Pins And Needles | 2011 | Oils | 12.0&quot; x 12.0&quot;",
      titleShort: "Sitting on Pins And Needles",
      imageTn: "May-2011-Sitting-on-Pins-And-Needles-Oil-12-x-12.jpg",
    },
  ],

  archive: [
    {
      imageFullsize: "IMG_2129.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Book Study I | 2007 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Img_2129",
      imageTn: "IMG_2129.jpg",
    },
    {
      imageFullsize: "IMG_2146.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Book Study II | 2007 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2146",
      imageTn: "IMG_2146.jpg",
    },
    {
      imageFullsize: "IMG_2165.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Book Study III | 2007 | Pastel/Watercolor | 18.0&quot; x 18.0&quot;",
      titleShort: "Img_2165",
      imageTn: "IMG_2165.jpg",
    },
    {
      imageFullsize: "IMG_2280.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Nature Collection I | 2008 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2280",
      imageTn: "IMG_2280.jpg",
    },
    {
      imageFullsize: "IMG_1777.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Blue Canton IV | 2007 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_1777",
      imageTn: "IMG_1777.jpg",
    },
    {
      imageFullsize: "IMG_2535.jpg?1236920713",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Pears with Mortar and Pestle | 2008 | Pastel | 16.0&quot; x 16.0&quot;",
      titleShort: "Pears with Mortar and Pestle",
      imageTn: "IMG_2535.jpg?1236920713",
    },
    {
      imageFullsize: "IMG_2585.jpg?1236921079",
      rel: "lightbox-paintings-Still Lifes",
      title: "Hanging Artichoke II | 2008 | Pastel | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2585",
      imageTn: "IMG_2585.jpg?1236921079",
    },
    {
      imageFullsize: "MangoDuet.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Mango Duet [sold] | 2007 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Mango Duet",
      imageTn: "MangoDuet.jpg",
    },
    {
      imageFullsize: "IMG_2604.jpg?1236921031",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Still Life with Grapes and Pears | 2008 | Pastel | 9.0&quot; x 9.0&quot;",
      titleShort: "Img_2604",
      imageTn: "IMG_2604.jpg?1236921031",
    },
    {
      imageFullsize: "IMG_1838_1.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Apples in a Basket [sold] | 2007 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Img_1838_1",
      imageTn: "IMG_1838_1.jpg",
    },
    {
      imageFullsize: "IMG_2569.jpg?1236920972",
      rel: "lightbox-paintings-Still Lifes",
      title: "Garlic Clove | 2008 | Pastel | 6.0&quot; x 6.0&quot;",
      titleShort: "Img_2569",
      imageTn: "IMG_2569.jpg?1236920972",
    },
    {
      imageFullsize: "IMG_2554.jpg?1236920921",
      rel: "lightbox-paintings-Still Lifes",
      title: "Red Grapes | 2008 | Pastel | 6.0&quot; x 6.0&quot;",
      titleShort: "Img_2554",
      imageTn: "IMG_2554.jpg?1236920921",
    },
    {
      imageFullsize: "EveningShadows.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Evening Shadows | 2006 | Pastel/Watercolor | 241.0&quot; x 241.0&quot;",
      titleShort: "Eveningshadows",
      imageTn: "EveningShadows.jpg",
    },
    {
      imageFullsize: "VermontFields_I.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Vermont Fields I [sold] | 2007 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Vermontfields_i",
      imageTn: "VermontFields_I.jpg",
    },
    {
      imageFullsize: "IMG_2435.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Morning Shadows, Maui HI | 2008 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Morning Shadows",
      imageTn: "IMG_2435.jpg",
    },
    {
      imageFullsize: "IMG_2328.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Alpine Mist, South Island, NZ | 2008 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Alpine Mist, South Island",
      imageTn: "IMG_2328.jpg",
    },
    {
      imageFullsize: "IMG_2323.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Shining Through, South Is. NZ | 2008 | Pastel/Watercolor | 15.0&quot; x 15.0&quot;",
      titleShort: "Shining Through",
      imageTn: "IMG_2323.jpg",
    },
    {
      imageFullsize: "IMG_2324.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Alpine Lupin, South Island, NZ | 2008 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Alpine Lupin",
      imageTn: "IMG_2324.jpg",
    },
    {
      imageFullsize: "MtPhiloRdBarnII.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Mt. Philo Rd. Barn I [sold] | 2007 | Pastel/Watercolor | 17.0&quot; x 17.0&quot;",
      titleShort: "Mt. Philo Rd. Barn I",
      imageTn: "MtPhiloRdBarnII.jpg",
    },
    {
      imageFullsize: "IMG_1843.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Barn Door [sold] | 2007 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Barn Door",
      imageTn: "IMG_1843.jpg",
    },
    {
      imageFullsize: "IMG_1853.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Barn Shadows II | 2007 | Pastel/Watercolor | 14.0&quot; x 14.0&quot;",
      titleShort: "Barn Shadows II",
      imageTn: "IMG_1853.jpg",
    },
    {
      imageFullsize: "IMG_2157.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Westport River, West Branch [sold] | 2007 | Pastel/Watercolor | 24.0&quot; x 24.0&quot;",
      titleShort: "Westport River, West Branch",
      imageTn: "IMG_2157.jpg",
    },
    {
      imageFullsize: "IMG_1839.jpg?1236918650",
      rel: "lightbox-paintings-Landscapes",
      title: "Red Barn at High Noon | 2007 | Pastel | 14.0&quot; x 14.0&quot;",
      titleShort: "Red Barn at High Noon",
      imageTn: "IMG_1839.jpg?1236918650",
    },
    {
      imageFullsize: "MidwayLightI.jpg",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Midway Light, Heber Valley, UT | 2006 | Pastel/Watercolor | 18.0&quot; x 18.0&quot;",
      titleShort: "Midway Light, Heber Valley",
      imageTn: "MidwayLightI.jpg",
    },
    {
      imageFullsize: "IMG_1828.jpg?1236918792",
      rel: "lightbox-paintings-Landscapes",
      title:
        "View from Kitchen Window | 2007 | Pastel | 18.0&quot; x 18.0&quot;",
      titleShort: "View from Kitchen Window",
      imageTn: "IMG_1828.jpg?1236918792",
    },
    {
      imageFullsize: "IMG_2437.jpg?1236919625",
      rel: "lightbox-paintings-Landscapes",
      title:
        "Sugar Cane Fields, Maui, HI | 2008 | Pastel | 14.0&quot; x 14.0&quot;  Sold",
      titleShort: "Sugar Cane Fields",
      imageTn: "IMG_2437.jpg?1236919625",
    },
    {
      imageFullsize: "May-2011-As-Time-Goes-By-Oil-22x25.jpg",
      rel: "lightbox-paintings-oils",
      title: "As Time Goes By | 2011 | Oil | 22&quot; x 25&quot;",
      titleShort: "As Time Goes By Oil",
      imageTn: "May-2011-As-Time-Goes-By-Oil-22x25.jpg",
    },
    {
      imageFullsize: "May-2011-Common-Threads-Oil-14x18.jpg",
      rel: "lightbox-paintings-oils",
      title: "Common Threads | 2011 | Oil | 14.0&quot; x 18.0&quot;",
      titleShort: "Common Threads",
      imageTn: "May-2011-Common-Threads-Oil-14x18.jpg",
    },
    {
      imageFullsize: "May-2011-High-Five-Oil-16x19.jpg",
      rel: "lightbox-paintings-oils",
      title: "High Five | 2011 | Oils | 16.0&quot; x 19.0&quot;",
      titleShort: "High Five",
      imageTn: "May-2011-High-Five-Oil-16x19.jpg",
    },
    {
      imageFullsize: "May-2011-Hole-In-One-Oil-12-x-12.jpg",
      rel: "lightbox-paintings-oils",
      title: "Hole In One Oil | 2011 | Oils | 12.0&quot; x 12.0&quot;",
      titleShort: "Hole In One",
      imageTn: "May-2011-Hole-In-One-Oil-12-x-12.jpg",
    },
    {
      imageFullsize: "May-2011-Menage-a-tois-oil-17x21.jpg",
      rel: "lightbox-paintings-oils",
      title: "Ménage à trois | 2011 | 17.0&quot; x 21.0&quot;",
      titleShort: "Ménage à trois",
      imageTn: "May-2011-Menage-a-tois-oil-17x21.jpg",
    },
    {
      imageFullsize: "May-2011-Mount-of-Olives-Oil-14x14.jpg",
      rel: "lightbox-paintings-oils",
      title: "Mount of Olives | 2011 | Oils | 14.0&quot; x 14.0&quot;",
      titleShort: "Mount of Olives",
      imageTn: "May-2011-Mount-of-Olives-Oil-14x14.jpg",
    },
    {
      imageFullsize: "May-2011-Nest-Egg-Oil-16x19.jpg",
      rel: "lightbox-paintings-oils",
      title: "Nest Egg | 2011 | Oils | 16.0&quot; x 19.0&quot;",
      titleShort: "Nest Egg",
      imageTn: "May-2011-Nest-Egg-Oil-16x19.jpg",
    },
    {
      imageFullsize: "May-2011-No-Strings-Attached-Oil-22x25.jpg",
      rel: "lightbox-paintings-oils",
      title: "No Strings Attached | 2011 | Oils | 22.0&quot; x 25.0&quot;",
      titleShort: "No Strings Attached",
      imageTn: "May-2011-No-Strings-Attached-Oil-22x25.jpg",
    },
    {
      imageFullsize: "May-2011-Sitting-on-Pins-And-Needles-Oil-12-x-12.jpg",
      rel: "lightbox-paintings-oils",
      title:
        "Sitting on Pins And Needles | 2011 | Oils | 12.0&quot; x 12.0&quot;",
      titleShort: "Sitting on Pins And Needles",
      imageTn: "May-2011-Sitting-on-Pins-And-Needles-Oil-12-x-12.jpg",
    },
    {
      imageFullsize: "Sturim_020.jpg",
      rel: "lightbox-paintings-Botanicals",
      title:
        "Morning Dew I | 2005 | Pastel/Watercolor | 12.0&quot; x 12.0&quot;",
      titleShort: "Sturim_020",
      imageTn: "Sturim_020.jpg",
    },
    {
      imageFullsize: "Sturim_021.jpg",
      rel: "lightbox-paintings-Botanicals",
      title:
        "Morning Dew II [sold] | 2005 | Pastel/Watercolor | 12.0&quot; x 12.0&quot;",
      titleShort: "Sturim_021",
      imageTn: "Sturim_021.jpg",
    },
    {
      imageFullsize: "Sturim_038.jpg",
      rel: "lightbox-paintings-Botanicals",
      title: "Lotus I | 2005 | Pastel/Watercolor | 17.0&quot; x 17.0&quot;",
      titleShort: "Sturim_038",
      imageTn: "Sturim_038.jpg",
    },
    {
      imageFullsize: "Sturim_039.jpg",
      rel: "lightbox-paintings-Botanicals",
      title: "Ladyslipper | 2005 | Pastel/Watercolor | 17.0&quot; x 17.0&quot;",
      titleShort: "Sturim_039",
      imageTn: "Sturim_039.jpg",
    },
    {
      imageFullsize: "IMG_2338.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Hanging Grapes | 2008 | Pastel/Watercolor | 16.0&quot; x 16.0&quot;",
      titleShort: "Img_2338",
      imageTn: "IMG_2338.jpg",
    },
    {
      imageFullsize: "IMG_2382_1.jpg?1236920478",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Orange on Silver | 2008 | Pastel/Watercolor | 11.0&quot; x 11.0&quot;",
      titleShort: "Img_2382_1",
      imageTn: "IMG_2382_1.jpg?1236920478",
    },
    {
      imageFullsize: "IMG_2313.jpg",
      rel: "lightbox-paintings-Still Lifes",
      title:
        "Tangarines on Red | 2008 | Pastel/Watercolor | 10.0&quot; x 10.0&quot;",
      titleShort: "Img_2313",
      imageTn: "IMG_2313.jpg",
    },
  ],

  pastels: [
    {
      imageFullsize: "18-Yellow-Birds-Nest-Pastel-10x10.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Yellow Birds Nest &mdash; Pastel, 10x10",
      titleShort: "Yellow Birds Nest &mdash; Pastel, 10x10",
      imageTn: "/assets/paintings/tn/18-Yellow-Birds-Nest-Pastel-10x10.jpg",
    },
    {
      imageFullsize: "19-Favorite-Things-Pastel-20x20.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Favorite Things &mdash; Pastel, 20x20",
      titleShort: "Favorite Things &mdash; Pastel, 20x20",
      imageTn: "/assets/paintings/tn/19-Favorite-Things-Pastel-20x20.jpg",
    },
    {
      imageFullsize: "17-Double-Birds-Nest-Pastel-23x12.jpg",
      rel: "lightbox-paintings-recent-works",
      title: "Double Birds Nest &mdash; Pastel, 23x12",
      titleShort: "Double Birds Nest &mdash; Pastel, 23x12",
      imageTn: "/assets/paintings/tn/17-Double-Birds-Nest-Pastel-23x12.jpg",
    },
  ],
};

export default paintings;
