/* == react modules */
import React from "react";

/* == node libraries */
import { Carousel, Container, Col, Image, Row } from "react-bootstrap";

/* == data */
import paintings from "./../data/paintings";

function Home() {
  const homePaintings = paintings["home"];
  const path = `${process.env.PUBLIC_URL}/images/paintings/fs`;

  return (
    <Container className="mt-4" fluid="md">
      <Row>
        <Col>
          <div style={{ textAlign: "center" }}>
            <h1 className="border-bottom border-secondary pb-2 mb-2">
              Jeanne Boehme Sturim
            </h1>
            <h2 className="h6 mt-3 mb-3">
              AUGUST 27, 1939 &mdash; JANUARY 9, 2018
            </h2>
            <div className="carousel-container">
              <Carousel touch={true} indicators={false} interval={5000}>
                {homePaintings.map((painting) => (
                  <Carousel.Item key={painting.titleShort}>
                    <Image src={`${path}/${painting.imageFullsize}`} fluid />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
