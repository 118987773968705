/* == react modules */
import React from "react";

/* == node modules */
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Container, Col, Row, Image } from "react-bootstrap";

/* == data */
import paintings from "./../data/paintings";

const camelToTitleCase = (str) => {
  return str
    .replace(/[0-9]{2,}/g, (match) => ` ${match} `)
    .replace(/[^A-Z0-9][A-Z]/g, (match) => `${match[0]} ${match[1]}`)
    .replace(
      /[A-Z][A-Z][^A-Z0-9]/g,
      (match) => `${match[0]} ${match[1]}${match[2]}`
    )
    .replace(/[ ]{2,}/g, (match) => " ")
    .replace(/\s./g, (match) => match.toUpperCase())
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

function Works() {
  const { section } = useParams();

  const sectionPaintings = paintings[section];
  const path = `${process.env.PUBLIC_URL}/images/paintings/fs`;

  return (
    <Container className="mt-4" fluid="md">
      <Row className="border-bottom border-secondary pb-1 mb-4">
        <Col>
          <h1>Works</h1>
        </Col>
        <Col className="d-flex justify-content-end align-self-center">
          <h2 className="h4">{camelToTitleCase(section)}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {sectionPaintings.map((painting) => (
            <figure key={painting.titleShort}>
              <Image
                className="mx-auto d-block"
                src={`${path}/${painting.imageFullsize}`}
                fluid
              />
              <figcaption className="mt-3 mb-5">
                {ReactHtmlParser(painting.title)}
              </figcaption>
            </figure>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default Works;
