/* == react modules */
import React from "react";

/* == node modules */
import { Container, Col, Row } from "react-bootstrap";

function Resume() {
  return (
    <Container className="mt-4" fluid="md">
      <Row>
        <Col>
          <h1 className="border-bottom border-secondary pb-2 mb-4">Resume</h1>
          <div id="resume">
            <h2 className="h5">Education:</h2>
            <ul>
              <li>Washington University, School of Fine Arts, BFA</li>
              <li>University of Rhode Island, BA</li>
              <li>
                Rhode Island School of Design, Illustration Dept.& Continuing
                Ed.
              </li>
            </ul>
            <h2 className="h5">Art Affiliation:</h2>
            <ul>
              <li>Art League of Rhode Island, Juried Artist Member</li>
              <li>Providence Art Club, Artist Member</li>
              <li>Attleboro Museum, Artist Member</li>
              <li>Pastel Society of America</li>
              <li>Connecticut Pastel Society</li>
            </ul>
            <h2 className="h5">Employment:</h2>
            <ul>
              <li>Rhode Island School of Design: Continuing Ed. Instructor</li>
              <li>Workshops in Pastel, 2007-2013</li>
            </ul>
            <h2 className="h5">Exhibitions:</h2>
            <ul>
              <li>Attleboro Museum Members Show, Dec. 2014-Feb 2015</li>
              <li>
                Art League of RI Annual Members Exhibition, 2014, 2013, 2012,
                2011, 2009, 2010, 2008, 2007, 2006
              </li>
              <li>Providence Art Club Members Show: 2014, 2009, 2008, 2007</li>
              <li>
                "Word Play" Works In Oil, Moite Gallery, Providence Art Club,
                2011
              </li>
              <li>
                Small Works Exhibition, Art League of Rhode Island, Royal
                Gallery, Providence, RI, 2009
              </li>
              <li>
                "Her Mark", Ten Woman Exhibition, Bayview Gallery, Brunswick, ME
                2009
              </li>
              <li>
                "Painting In Pastel", Dodge Gallery, Providence Art Club, 2007,
              </li>
              <li>
                Solo Exhibit Art League of Rhode Island Group Fall Exhibition
                2006, R I Foundation
              </li>
              <li>Providence Art Club, 2005, Two Person Exhibit</li>
            </ul>
            <h2 className="h5">Juried Exhibitions:</h2>
            <ul>
              <li>
                Catherine Lorillard Wolfe Art Club, Annual Open Exhibition,
                National Art Club, New York, Dec. 2014, 2010, 2009
              </li>
              <li>Renaissance In Pastel, Connecticut Pastel Society, Annual</li>
              <li>National Exhibition, 2009, 2007</li>
              <li>Cape Cod Art Association National Exhibition, 2009</li>
              <li>
                Pastel Society of America International Exhibition, National Art
                Club, New York, 2007, 2006
              </li>
              <li>
                Pastel Painters of Maine International Juried Exhibition2006
              </li>
              <li>
                Pastels Only Exhibition: 2005 Pastel Painters Society of Cape
                Cod, 2006
              </li>
              <li>Northeast National Pastel Exhibition, 2005</li>
            </ul>
            <h2 className="h5">Awards:</h2>
            <ul>
              <li>
                Hunter Editions Award of Excellence, Renaissance In Pastel
                Exhibition,
              </li>
              <li>Connecticut Pastel Society Annual Exhibition, 2009</li>
              <li>
                First Place in Pastel, Cape Cod Art Association National
                Exhibition, 2009
              </li>
              <li>
                The Providence Art Club Award, Providence Art Club Members
                Exhibition:
              </li>
              <li>Words, Letters and Text, 2008</li>
              <li>Pastel Painters Society of Cape Cod 2005, Award of Merit</li>
            </ul>
            <h2 className="h5">Galleries:</h2>
            <ul>
              <li>Royal Gallery, Providence, RI</li>
            </ul>
            <h2 className="h5">Collections:</h2>
            <ul>
              <li>The Lutheran Heritage Museum, Altenburg, Missouri</li>
              <li>Providence Art Club, Permanent Collection</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Resume;
