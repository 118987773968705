/* == react modules */
import React from "react";

/* == node libraries */
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

function Navigation() {
  return (
    <Navbar sticky="top" collapseOnSelect bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <img
          src="/favicon-32x32.png"
          width="32"
          height="32"
          className="d-inline-block align-top border border-secondary"
          alt="JeanneSturim.com"
        />
        <span className="ml-3 text-decoration-none" to="/">
          JeanneSturim.com
        </span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <NavDropdown title="Works" id="collasible-nav-dropdown">
            <LinkContainer to="/works/oils">
              <NavDropdown.Item>Oils</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/works/landscapes">
              <NavDropdown.Item>Landscapes</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/works/stillLifes">
              <NavDropdown.Item>Still Lifes</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/works/botanicals">
              <NavDropdown.Item>Botanicals</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/works/pastels">
              <NavDropdown.Item>Pastels</NavDropdown.Item>
            </LinkContainer>

            <NavDropdown.Divider />

            <LinkContainer to="/works/archive">
              <NavDropdown.Item>Archive</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          <NavDropdown title="About" id="collasible-nav-dropdown">
            <LinkContainer to="/artists-statement">
              <NavDropdown.Item>Artist's Statement</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/resume">
              <NavDropdown.Item>Resume</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <div className="centered-menu">Collection notes</div>
            <LinkContainer to="/word-play">
              <NavDropdown.Item>Word Play</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
